(function () {
  var $ = window.$;

  $(document).ready(function () {
    $('select.chosen-select').addClass('document-ready');

    if ($(document).tooltip) {
      $(document).tooltip('destroy');
      $('[data-toggle="tooltip"]').tooltip('destroy');
    }

    $.fn.modal.Constructor.prototype._enforceFocus = function() {};
  });

})()